'use client'

import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import Image from 'next/image'

const NotFoundPage = () => {
	const imageValidate = useImageValidate()

	return (
		<>
			<style>
				{
					`
						#wrap-content {
							display: flex;
							align-items: center;
							justify-content: center;
						}
					`
				}
			</style>

			<section
				className="bg-dg-100 dark:bg-dgdark-100"
				id="error"
			>
				<Image
					alt="ライブショッピングはQ0010のLiveStudioから！LIVEだけの特典やイベントが盛りだくさん！"
					className="object-cover"
					height={800}
					onError={imageValidate.onError}
					src="/images/pc/common-live/live-qr.jpg"
					width={980}
				/>
			</section>
		</>
	)
}

export default NotFoundPage
